
// These are generated by icomoon.io, if you want to change them, go to their app,
// then import the .svg in machool-icons/, and edit in their gui, and generate fonts
@font-face {
  font-family: 'machool-icons';
  font-style: normal;
  font-weight: normal;
  src: url('https://cdn.machool.com/shared/fonts/machool-icons/icons.woff');
  src:
    url('https://cdn.machool.com/shared/fonts/machool-icons/icons.eot') format('embedded-opentype'),
    url('https://cdn.machool.com/shared/fonts/machool-icons/icons.ttf') format('truetype'),
    url('https://cdn.machool.com/shared/fonts/machool-icons/icons.woff') format('woff'),
    url('https://cdn.machool.com/shared/fonts/machool-icons/icons.svg') format('svg');
}

.rotate {
  &-right {
    &-90 {
      display: inline-block;
      transform: rotate(90deg);
    }
    &-180 {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  &-left {
    &-90 {
      display: inline-block;
      transform: rotate(-90deg);
    }
    &-180 {
      display: inline-block;
      transform: rotate(-180deg);
    }
  }
}

.icon-3x {
  font-size: 3rem;
}

.icon-1x {
  font-size: 1rem;
}

.icon-offset:before {
  position: relative;
  top: 1px;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'machool-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.circle-bg {
    background: map-get($colors, primary-green);
    border-radius: 50%;
    padding: 2px;
    color: map-get($colors, white);

    &.icon-express {
      background: map-get($colors, shipping-express);
    }

    &.icon-leaf {
      background: map-get($colors, report-green);
      font-size: 0.625rem;
      padding: 4px;
    }

    &.icon-ground {
      background: map-get($colors, shipping-ground);
    }

    &.icon-cart {
      background: map-get($colors, shipping-cart);
      font-size: 0.625rem;
      padding: 4px;
      position: relative;
      top: -3px;
    }

    &.icon-api {
      background: map-get($colors, api)
    }

    &.icon-connect {
      background: map-get($colors, connect);
      font-size: 0.625rem;
      padding: 4px 4px 4px 5px;
    }
  }
}

.icon-cqcd:before {
  content: "\e995";
}
.icon-nationex:before {
  content: "\e996";
}
.icon-panierdachat:before {
  content: "\e997";
}
.icon-chevron-up:before {
  content: "\e993";
}
.icon-chevron-down:before {
  content: "\e994";
}
.icon-target:before {
  content: "\e991";
}
.icon-connect:before {
  content: "\e990";
}
.icon-api:before {
  content: "\e98f";
}
.icon-hold:before {
  content: "\e98e";
}
.icon-charge:before {
  content: "\e98d";
}
.icon-redirects:before {
  content: "\e98c";
}
.icon-home:before {
  content: "\e919";
}
.icon-frozen:before {
  content: "\e98a";
}
.icon-assign:before {
  content: "\e98b";
}
.icon-eye-close:before {
  content: "\e988";
}
.icon-preview:before {
  content: "\e987";
}
.icon-eye-open:before {
  content: "\e989";
}
.icon-list:before {
  content: "\e940";
}
.icon-twinkle:before {
  content: "\e984";
}
.icon-dots:before {
  content: "\e985";
}
.icon-bell:before {
  content: "\e986";
}
.icon-merge:before {
  content: "\e983";
}
.icon-fragile:before {
  content: "\e982";
}
.icon-history:before {
  content: "\e981";
}
.icon-note:before {
  content: "\e980";
}
.icon-dangerous:before {
  content: "\e97f";
}
.icon-plugin:before {
  content: "\e97b";
}
.icon-ignore:before {
  content: "\e97c";
}
.icon-express:before {
  content: "\e97d";
}
.icon-ground:before {
  content: "\e97e";
}
.icon-no-docs:before {
  content: "\e97a";
}
.icon-duties:before {
  content: "\e979";
}
.icon-circle-plus:before {
  content: "\e96a";
}
.icon-circle-minus:before {
  content: "\e978";
}
.icon-account:before {
  content: "\e974";
}
.icon-loupe:before {
  content: "\e90c";
}
.icon-goback:before {
  content: "\e905";
}
.icon-user-mag:before {
  content: "\e970";
}
.icon-logo:before {
  content: "\e971";
}
.icon-calendar:before {
  content: "\e972";
}
.icon-book:before {
  content: "\e973";
}
.icon-cart:before {
  content: "\e977";
}
.icon-ex-link:before {
  content: "\e975";
}
.icon-noservice:before {
  content: "\e976";
}
.icon-checked:before {
  content: "\e96d";
}
.icon-error:before {
  content: "\e96e";
}
.icon-no:before {
  content: "\e96f";
}
.icon-lock:before {
  content: "\e96c";
}
.icon-filter:before {
  content: "\e96b";
}
.icon-handling:before {
  content: "\e969";
}
.icon-leaf:before {
  content: "\e966";
}
.icon-saturday:before {
  content: "\e967";
}
.icon-direct:before {
  content: "\e968";
}
.icon-refresh:before {
  content: "\e963";
}
.icon-globe:before {
  content: "\e964";
}
.icon-checkedbox:before {
  content: "\e965";
}
.icon-chevron-right:before {
  content: "\e900";
}
.icon-chevron-left:before {
  content: "\e901";
}
.icon-arrow-circle-right:before {
  content: "\e902";
}
.icon-arrow-circle-left:before {
  content: "\e903";
}
.icon-plane-dash:before {
  content: "\e904";
}
.icon-user:before {
  content: "\e906";
}
.icon-edit-plus:before {
  content: "\e907";
}
.icon-upload:before {
  content: "\e908";
}
.icon-download:before {
  content: "\e909";
}
.icon-time:before {
  content: "\e90a";
}
.icon-config:before {
  content: "\e90b";
}
.icon-report:before {
  content: "\e90d";
}
.icon-zip:before {
  content: "\e90e";
}
.icon-music:before {
  content: "\e90f";
}
.icon-pdf:before {
  content: "\e910";
}
.icon-video:before {
  content: "\e911";
}
.icon-image:before {
  content: "\e912";
}
.icon-copy:before {
  content: "\e913";
}
.icon-print:before {
  content: "\e914";
}
.icon-mail:before {
  content: "\e915";
}
.icon-box:before {
  content: "\e916";
}
.icon-timer:before {
  content: "\e917";
}
.icon-keyboard:before {
  content: "\e918";
}
.icon-browser-graph:before {
  content: "\e91a";
}
.icon-camera:before {
  content: "\e91b";
}
.icon-check:before {
  content: "\e91c";
}
.icon-plus:before {
  content: "\e91d";
}
.icon-minus:before {
  content: "\e91e";
}
.icon-tongue:before {
  content: "\e91f";
}
.icon-neutral:before {
  content: "\e920";
}
.icon-angry:before {
  content: "\e921";
}
.icon-confusing:before {
  content: "\e922";
}
.icon-smile:before {
  content: "\e923";
}
.icon-girl:before {
  content: "\e924";
}
.icon-building:before {
  content: "\e925";
}
.icon-exit:before {
  content: "\e926";
}
.icon-balloon:before {
  content: "\e927";
}
.icon-bill:before {
  content: "\e928";
}
.icon-id:before {
  content: "\e929";
}
.icon-calendar-face:before {
  content: "\e92a";
}
.icon-robot:before {
  content: "\e92b";
}
.icon-bill-dollar:before {
  content: "\e92c";
}
.icon-ship:before {
  content: "\e92d";
}
.icon-trophy:before {
  content: "\e92e";
}
.icon-iphone:before {
  content: "\e92f";
}
.icon-cloud:before {
  content: "\e930";
}
.icon-arrow-left:before {
  content: "\e931";
}
.icon-arrow-down:before {
  content: "\e932";
}
.icon-arrorw-up:before {
  content: "\e933";
}
.icon-ticket:before {
  content: "\e934";
}
.icon-moon:before {
  content: "\e935";
}
.icon-sun:before {
  content: "\e936";
}
.icon-rain:before {
  content: "\e937";
}
.icon-thunder:before {
  content: "\e938";
}
.icon-cloud-upload:before {
  content: "\e939";
}
.icon-cloud-download:before {
  content: "\e93a";
}
.icon-arrow-right:before {
  content: "\e93b";
}
.icon-enter:before {
  content: "\e93c";
}
.icon-backspace:before {
  content: "\e93d";
}
.icon-x:before {
  content: "\e93e";
}
.icon-map:before {
  content: "\e93f";
}
.icon-return:before {
  content: "\e941";
}
.icon-truck:before {
  content: "\e942";
}
.icon-scale:before {
  content: "\e943";
}
.icon-language:before {
  content: "\e944";
}
.icon-document:before {
  content: "\e945";
}
.icon-scissor:before {
  content: "\e946";
}
.icon-graph:before {
  content: "\e947";
}
.icon-good:before {
  content: "\e948";
}
.icon-bad:before {
  content: "\e949";
}
.icon-hint:before {
  content: "\e94a";
}
.icon-info:before {
  content: "\e94b";
}
.icon-warn:before {
  content: "\e94c";
}
.icon-battery-full:before {
  content: "\e94d";
}
.icon-battery-60:before {
  content: "\e94e";
}
.icon-doc-checked:before {
  content: "\e94f";
}
.icon-age-21:before {
  content: "\e950";
}
.icon-age-19:before {
  content: "\e951";
}
.icon-age-18:before {
  content: "\e952";
}
.icon-signature:before {
  content: "\e953";
}
.icon-folder:before {
  content: "\e954";
}
.icon-bill-euro:before {
  content: "\e955";
}
.icon-usb:before {
  content: "\e956";
}
.icon-battry-empty:before {
  content: "\e957";
}
.icon-battry-30:before {
  content: "\e958";
}
.icon-box-collect:before {
  content: "\e959";
}
.icon-box-return:before {
  content: "\e95a";
}
.icon-laptop:before {
  content: "\e95b";
}
.icon-floppy:before {
  content: "\e95c";
}
.icon-chevron-w-down:before {
  content: "\e95d";
}
.icon-chevron-w-up:before {
  content: "\e95e";
}
.icon-chevron-w-left:before {
  content: "\e95f";
}
.icon-chevron-w-right:before {
  content: "\e960";
}
.icon-caret-line-up:before {
  content: "\e961";
}
.icon-caret-line-down:before {
  content: "\e962";
}
.icon-building-new:before {
  content: "\e9ab";
}
.icon-creditcard:before {
  content: "\e9ac";
}
.icon-store:before {
  content: "\e9ad";
}
.icon-edit:before {
  content: "\e9ae";
}
.icon-bin:before {
  content: "\e9af";
}
.icon-users:before {
  content: "\e9aa";
}
.icon-caret-up:before {
  content: "\e992";
}
.icon-caret-down:before {
  content: "\e9a0";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-facebook:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eac9";
}


.chart-tooltip {
  color: #FFFFFF;
  padding: 10px;
  margin: 10px;
  font-size: 0.75rem;
  .positive {
    color: #198754 !important;
  }
  .negative {
    color: #dc3545 !important;
  }
}

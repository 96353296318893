.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}
.mat-mdc-menu-item .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm {
  height: 14px;
  width: 14px;
  font-size: 14px;
  line-height: 14px;
}
.mat-mdc-menu-item .mat-icon.mat-menu-icon-after {
  order: 2;
}

.mat-mdc-table {
  .expansion-row {
    height: 0;
  }
  .expanded-detail {
    overflow: hidden;
    display: flex;
  }

  .expanded-shipment-detail {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: 0;
    &.expanded {
      max-height: 1000px;
    }
  }

  .mat-mdc-header-cell {
    text-transform: uppercase;
    color: map-get($colors, table-header);
    font-weight: map-get($font-weights, semi-bold);
    font-size: 0.75rem;
  }
}
.table-striped {
  &.tracking-progress{
    .mdc-data-table__row {
      height: 90px;
    }
    .cdk-column-date {
      width: 140px;
    }
  }
  tr:nth-of-type(even) {
    background: map-get($colors, accent-gray);
    pointer-events: none; // disables hover effect from angular
  }
}


.progress-circle {
  position: relative;
  background: map-get($colors, light-gray);
  width: 14px;
  height: 14px;
  border-radius: 50%;

  &.latest {
    background: map-get($colors, primary-green) !important;
    &::after {
      display: none;
    }
  }

  &.first {
    &::before {
      display: none;
    }
  }

  &::before {
    @include before-progress-line;
  }

  &::after {
    @include after-progress-line;
  }
}

.mat-mdc-table .mat-mdc-table .mdc-data-table__header-row {
  height: 40px;
}
.mat-mdc-table .mat-mdc-table .mdc-data-table__row {
  height: 30px;
  border-color: map-get($colors, borders);
}
.responsive-table {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  > table {
    width: 100%;
    min-width: 1024px;
  }
}

.outer-circle {
  background: white;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  position: relative;

  &.created {
    border: solid 1px map-get($colors, created);
  }

  &.transit {
    border: solid 1px map-get($colors, transit);
  }

  &.delivered {
    border: solid 1px map-get($colors, primary-green);
  }
}

.ticket {
  position: relative;
  padding-left: 60px;
  img {
    max-width: 100% !important;
  }
  &:before {
    position: absolute;
    left: 15px;
    top: 0;
    width: 30px;
    height: 30px;
    content: '';
  }
  &.private:after {
    position: absolute;
    left: 32px;
    top: 18px;
    width: 18px;
    height: 18px;
    content: '';
    background: url('https://cdn.machool.com/shared/images/support/private.svg') no-repeat center center;
  }
  &.author-user {
    &:before {
      background: url('https://cdn.machool.com/shared/images/support/user.svg') no-repeat center center;
      background-size: contain;
    }
  }
  &.author-machool {
    &:before {
      background: url('https://cdn.machool.com/shared/images/support/machool.svg') no-repeat center center;
      background-size: contain;
    }
  }
}

.mat-accordion .mat-expansion-panel-flat,
.mat-expansion-panel-flat {
  border: 0;
  box-shadow: none;
  .mat-expansion-panel-body {
    padding: 1rem 0 0 0;
  }
}

.mat-accordion {
  &.expansion-list {
    .mat-expansion-panel {
      border-radius: 0;
      border-top: 1px solid map-get($colors, borders);
      margin: 0;
      &:first-child {
        border-top: none;
      }
    }
    .mat-expansion-panel-header {
      height: fit-content;
      padding: 24px;
      .mat-expansion-panel-header-title {
        font-weight: map-get($font-weights, semi-bold);
        color: map-get($colors, primary-green);
        .mat-icon {
          font-size: 1rem;
          height: 1rem;
          width: 1rem;
          padding-right: 1rem;
        }
      }
      .expand-panel-icon {
        display: inline-block;
      }
      .minimize-panel-icon {
        display: none;
      }
      &.mat-expanded {
        .expand-panel-icon {
          display: none;
        }
        .minimize-panel-icon {
          display: inline-block;
        }
      }
    }
  }
}

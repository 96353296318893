.mtx-recon-tooltip {
  .mdc-tooltip__surface {
    width: auto !important;
    text-align: left !important;
    max-width: 250px !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    color: rgba(49, 49, 49, 0.75) !important;
    line-height: 1.5 !important;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.2) !important;
  }
}
.nowrap-tooltip {
  .mdc-tooltip__surface {
    max-width: unset !important;
  }
}
